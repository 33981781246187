import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as styles from './Quote.module.scss';

interface QuoteProps {
  text: string;
  author: string;
}

const Quote = ({ text, author }: QuoteProps): React.ReactElement => {
  return (
    <blockquote className={styles.container}>
      <FontAwesomeIcon icon="quote-left" className={styles.icon} />
      <div className={styles.rightSide}>
        <p className={styles.text + ' titleParagraph'}>{text}</p>
        {!!author && <span className={styles.author}>{author}</span>}
      </div>
    </blockquote>
  );
};

export default Quote;
