import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatBytes } from '../utils/utils';

import * as styles from './FileRow.module.scss';
import { useTranslation } from 'react-i18next';

interface FileRowProps {
  title: string;
  description: string;
  size?: number;
  url: string;
  small?: boolean;
  category?: string;
  date?: Date;
  className?: string;
  onDownloadClick?: ((url: string) => void) | null;
}

const FileRow = ({
  title,
  className,
  description,
  size,
  url,
  small,
  category,
  date,
  onDownloadClick,
}: FileRowProps): React.ReactElement => {
  const { t } = useTranslation();

  let formatedDate;
  if (date) {
    const month = [
      t('months.short_name.january', 'jan'),
      t('months.short_name.february', 'feb'),
      t('months.short_name.march', 'mar'),
      t('months.short_name.april', 'apr'),
      t('months.short_name.may', 'may'),
      t('months.short_name.june', 'jun'),
      t('months.short_name.july', 'jul'),
      t('months.short_name.august', 'aug'),
      t('months.short_name.september', 'sep'),
      t('months.short_name.october', 'oct'),
      t('months.short_name.november', 'nov'),
      t('months.short_name.december', 'dec'),
    ][date.getMonth()].toUpperCase();
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    formatedDate = day + ' ' + month + ' ' + date.getFullYear();
  }

  const fileDownloadUrl = url + '?dl';

  return (
    <div className={styles.file + (small ? ' ' + styles.fileSmall : '') + ' ' + className}>
      <div className={styles.titleAndDescription}>
        <div className={styles.titleContent}>
          <span className={styles.fileTitle} title={title}>
            {title}
          </span>
          {!!category && <span className={styles.fileCategory}>{category}</span>}
        </div>
        <span className={styles.fileDescription}>{description}</span>
      </div>
      <div className={styles.fileDetailsContainer}>
        {date && <span className={styles.fileDate}>{formatedDate}</span>}
        <span className={styles.fileSize}>{size !== undefined ? formatBytes(size, 0) : '---'}</span>
        <span className={styles.fileSizeDecimal}>
          {size !== undefined ? formatBytes(size, 1) : '---'}
        </span>

        <a href={url} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon="external-link-alt" className={styles.icon} />
        </a>
        <a
          href={!onDownloadClick ? fileDownloadUrl : undefined}
          onClick={onDownloadClick ? () => onDownloadClick(fileDownloadUrl) : undefined}
        >
          <FontAwesomeIcon icon="download" className={styles.icon} />
        </a>
      </div>
    </div>
  );
};

export default FileRow;
