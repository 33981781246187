import React from 'react';
import ImageGallery from 'react-image-gallery';

import { getReferenceUrl } from './utils/utils';
import GenericLink from './components/GenericLink';
import Video from './components/Video';
import FileRow from './components/FileRow';
import Quote from './components/Quote';

import * as styles from './serializers.module.scss';

export const PageLinkSerializer = (props: {
  mark: {
    reference: {
      slug: { current: string };
      internal: { type: 'SanityPage' };
    };
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <GenericLink to={getReferenceUrl('SanityPage', props.mark.reference.slug.current)}>
      {props.children}
    </GenericLink>
  );
};

export const GenericLinkSerializer = (props: {
  mark: { url: string };
  children: React.ReactNode;
}): React.ReactElement => {
  return <GenericLink to={props.mark.url}>{props.children}</GenericLink>;
};

export const EmbeddedImageSerializer = (props: {
  node: {
    image: {
      asset: {
        originalFilename: string;
        url: string;
      };
    };
    imageWidth: number;
  };
}): React.ReactElement => {
  return (
    <div className={styles.embbededImageWrapper}>
      <img
        src={props.node.image.asset.url + ('?w=' + (props.node.imageWidth || '930') + '&fit=max')}
        alt={props.node.image.asset.originalFilename}
      />
    </div>
  );
};

export const QuoteSerializer = (props: {
  node: {
    text: string;
    author: string;
  };
}): React.ReactElement => {
  return (
    <div className={styles.quote}>
      <Quote text={props.node.text} author={props.node.author}></Quote>
    </div>
  );
};

export const YoutubeSerializer = (props: { node: { url: string } }): React.ReactElement => {
  return (
    <div className={styles.video}>
      <Video url={props.node.url}></Video>
    </div>
  );
};

export const ImageGallerySerializer = (props: {
  node: {
    images: Array<{
      asset: {
        originalFilename: string;
        url: string;
      };
    }>;
  };
}): React.ReactElement => {
  return (
    <ImageGallery
      items={props.node.images
        .filter(image => image?.asset?.url)
        .map(image => ({
          original: image.asset.url + '?w=1000&h=600&fit=crop',
          fullscreen: image.asset.url + '?w=1000&h=600&fit=crop',
          thumbnail: image.asset.url + '?w=100&h=67&fit=crop',
          originalAlt: image.asset.originalFilename,
        }))}
      additionalClass={styles.imageGallery}
      showBullets
    />
  );
};

export const NewsArticleLinkSerializer = (props: {
  mark: {
    reference: {
      slug: { current: string };
      contentType: {
        slug: { current: string };
      };
      internal: { type: 'SanityNewsArticle' };
    };
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <GenericLink
      to={getReferenceUrl(
        'SanityNewsArticle',
        props.mark.reference.slug.current,
        props.mark.reference.contentType.slug.current,
      )}
    >
      {props.children}
    </GenericLink>
  );
};

export const ResourceFileSerializer = (props: {
  node: {
    file: {
      asset: {
        size: number;
        url: string;
      };
    };
    name: string;
    description: string;
  };
}): React.ReactElement => {
  return (
    <FileRow
      title={props.node.name}
      className={styles.fileInNews}
      description={props.node.description}
      url={props.node.file.asset.url}
      size={props.node.file.asset.size}
    ></FileRow>
  );
};
