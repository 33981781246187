// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var file = "FileRow-module--file--46y68";
export var fileCategory = "FileRow-module--fileCategory--fVr64";
export var fileDate = "FileRow-module--fileDate--B-XnT";
export var fileDescription = "FileRow-module--fileDescription--SyEFN";
export var fileDetailsContainer = "FileRow-module--fileDetailsContainer--mgrjm";
export var fileSize = "FileRow-module--fileSize--6XSUp";
export var fileSizeDecimal = "FileRow-module--fileSizeDecimal--PPJqf";
export var fileSmall = "FileRow-module--fileSmall--ze6BR";
export var fileTitle = "FileRow-module--fileTitle--9svnO";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "FileRow-module--icon--7-6mJ";
export var iconDisabled = "FileRow-module--iconDisabled--fB8A+";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var titleAndDescription = "FileRow-module--titleAndDescription--IICYr";
export var titleContent = "FileRow-module--titleContent--IWED1";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";